import React from "react";
import { Grid, Typography, TextField, Box } from "@mui/material";
import { colors } from "../../lib/theme";
import { useAuth } from "../../../shared/useAuth";

const AccountSetup = ({ formData, handleChange }) => {
  const { user, loading } = useAuth();

  return !user && (
    <Box sx={{ mb: 6 }}>
      <Typography variant="h3" gutterBottom color={colors.navy}>
        Account Setup
      </Typography>
      <Typography variant="h5" gutterBottom color={colors.navy}>
        Your Info
      </Typography>
      <Typography variant="body1" gutterBottom color={colors.navy} sx={{ mb: 3 }}>
        Creating an account helps you manage the subscription and make sure you have access to backups of any memories recorded.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom color={colors.navy}>
            First Name
          </Typography>
          <TextField
            name="firstName"
            required
            fullWidth
            value={formData.firstName}
            onChange={handleChange}
            sx={{ maxWidth: '100%' }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom color={colors.navy}>
            Last Name
          </Typography>
          <TextField
            name="lastName"
            required
            fullWidth
            value={formData.lastName}
            onChange={handleChange}
            sx={{ maxWidth: '100%' }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom color={colors.navy}>
            Email
          </Typography>
          <TextField
            name="email"
            required
            fullWidth
            type="email"
            value={formData.email}
            onChange={handleChange}
            sx={{ maxWidth: '100%' }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom color={colors.navy}>
            Password
          </Typography>
          <TextField
            name="password"
            required
            fullWidth
            type="password"
            value={formData.password}
            onChange={handleChange}
            sx={{ maxWidth: '100%' }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h6"
            gutterBottom
            color={colors.navy}
          >
            Confirm Password
          </Typography>
          <TextField
            name="confirmPassword"
            required
            fullWidth
            type="password"
            value={formData.confirmPassword}
            onChange={handleChange}
            sx={{ maxWidth: '100%' }}
          />
        </Grid>
      </Grid>
    </Box>
  )
};

export default AccountSetup;
